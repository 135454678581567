import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IRegistrationSupportCase, IRegistrationSupportee } from '../../../reports/models';
import { ISupportCase, ISupportee } from '../../models';

interface Props {
    supportCase: ISupportCase | IRegistrationSupportCase;
}

export const SupportCaseSupportees: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();

    const supportees = useMemo(
        () =>
            (supportCase.supportees as (ISupportee | IRegistrationSupportee)[])
                .filter(({ id }: ISupportee | IRegistrationSupportee) => id !== supportCase.primarySupportee?.id)
                .map(({ lastName, firstName, anonymous }: ISupportee | IRegistrationSupportee) =>
                    anonymous ? t('anonymousLabel') : `${lastName} ${firstName}`,
                )
                .join(', '),
        [supportCase.supportees],
    );

    return supportCase.primarySupportee?.anonymous ? (
        <Box sx={{ fontStyle: 'italic' }}>{t('anonymousLabel')}</Box>
    ) : (
        <>
            <div>
                {supportCase.primarySupportee?.lastName} {supportCase.primarySupportee?.firstName}
                <br />
                <Typography variant="caption" noWrap>
                    {supportees}
                </Typography>
            </div>
        </>
    );
};

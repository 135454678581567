import { useCubeQuery } from '@cubejs-client/react';
import { useIsFetching } from '@tanstack/react-query';
import { queryKeys } from '../../shared';
import { cubejsApi } from '../clients';
import { IFilter } from '../models';
import { dateFilterGenerator, filterGenerator } from '../utils';

export const useRegistrationPerGenderAndAgeReport = (
    filter: IFilter,
    dimensions = ['Registration.supporttypeid', 'Registration.organisationid'],
) => {
    const organisationsFetching = useIsFetching({ queryKey: [queryKeys.ORGANISATIONS] });
    const supportTypesFetching = useIsFetching({ queryKey: [queryKeys.SUPPORT_TYPES] });

    return useCubeQuery(
        {
            dimensions,
            timeDimensions: [
                {
                    dimension: 'Registration.dateofregistration',
                    granularity: filter.grouping || undefined,
                },
            ],
            order: {
                'Registration.dateofregistration': 'desc',
            },
            measures: [
                'SupporteeStatus.unique',
                'SupporteeStatus.count_unique_MALE',
                'SupporteeStatus.count_unique_FEMALE',
                'SupporteeStatus.count_unique_OTHER',
                'SupporteeStatus.count_unique_GENDER_UNKNOWN',
                'SupporteeStatus.count_unique_age_0AND2',
                'SupporteeStatus.count_unique_age_3AND5',
                'SupporteeStatus.count_unique_age_6AND11',
                'SupporteeStatus.count_unique_age_12AND15',
                'SupporteeStatus.count_unique_age_16AND17',
                'SupporteeStatus.count_unique_age_18AND24',
                'SupporteeStatus.count_unique_age_25AND59',
                'SupporteeStatus.count_unique_age_60AND64',
                'SupporteeStatus.count_unique_age_65AND999',
                'SupporteeStatus.count_unique_AGE_UNKNOWN',
            ],
            filters: [
                ...filterGenerator(filter.organisations || [], 'Registration.organisationid'),
                ...filterGenerator(filter.supportTypes || [], 'Registration.supporttypeid'),
                ...dateFilterGenerator('Registration.dateofregistration', filter.startDate, filter.endDate),
            ],
            limit: 5000,
        },
        { cubejsApi, skip: organisationsFetching != 0 || supportTypesFetching != 0 || !filter.enabled },
    );
};

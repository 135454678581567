export const reportsTranslationsNL = {
    reports: 'Rapporten',
    totalRegistrations: 'Aantal registraties',
    amountMale: 'Aantal mannen',
    amountFemale: 'Aantal vrouwen',
    dateOfRegistration: 'Datum',
    ageBetween0And5: 'Leeftijd 0-5',
    report: {
        registrations: 'Registraties',
        registrationSupportCaseStatusTitle: 'Dossiers',
        registrationSupporteeStatusTitle: 'Hulpvragers',
        newSupportCasesTitle: 'Nieuwe dossiers',
        download: {
            registrationSupportCaseStatus: 'registraties-dossiers',
            registrationSupporteeStatus: 'rapport-registraties-hulpvragers',
            newSupportCases: 'rapport-Nieuwe dossiers',
        },
        registration: {
            dateofregistration: {
                day: 'Dag',
                month: 'Maand',
                year: 'Jaar',
            },
            supporttypeid: 'Hulptype',
            organisationid: 'Organisatie',
            count: 'Totaal aantal registraties',
            unique: 'Aantal unieke registraties',
        },
        supportcasestatus: {
            anonymouscount: 'Waarvan anonieme registraties',
        },
        supporteestatus: {
            count: 'Totaal aantal bereikte hulpvragers',
            unique: 'Aantal uniek bereikte hulpvragers',
            count_unique_male: 'Aantal mannen',
            count_unique_female: 'Aantal vrouwen',
            count_unique_other: 'Aantal andere',
            count_unique_gender_unknown: 'Aantal geslacht onbekend',
            count_unique_age_0and2: 'Leeftijd 0-2',
            count_unique_age_3and5: 'Leeftijd 3-5',
            count_unique_age_6and11: 'Leeftijd 6-11',
            count_unique_age_12and15: 'Leeftijd 12-15',
            count_unique_age_16and17: 'Leeftijd 16-17',
            count_unique_age_18and24: 'Leeftijd 18-24',
            count_unique_age_25and59: 'Leeftijd 25-59',
            count_unique_age_60and64: 'Leeftijd 60-64',
            count_unique_age_65and999: 'Leeftijd 65+',
            count_unique_age_unknown: 'Aantal leeftijd onbekend',
        },
        supportcase: {
            createddate: {
                day: 'Dag',
                month: 'Maand',
                year: 'Jaar',
            },
            count: 'Aantal dossiers',
        },
        supportcaseorganisationsorganisation: { organisationid: 'Organisatie' },
    },
    startDate: 'Begindatum',
    endDate: 'Einddatum',
    all: 'Alle',
    refresh: 'Vernieuw',
    total: 'Totaal',
    export: 'Exporteer rapport',
    filter: 'Filter',
    options: 'Opties',
    grouping: 'Groepering',
    none: 'Geen',
    day: 'Dag',
    month: 'Maand',
    year: 'Jaar',
    sum: 'Som',
};

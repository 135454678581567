import { useMemo } from 'react';
import { ISupportType } from '../../models';
import { useSupportTypes } from './support-types.hook';

export function useSupportTypesRecord(): Record<string, ISupportType> {
    const supportTypes = useSupportTypes();
    return useMemo(() => {
        return supportTypes.reduce((obj, item): any => (item.id && (obj[item.id] = item), obj), {} as any);
    }, [supportTypes]);
}

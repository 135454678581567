import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../shared';
import { IDashboard } from '../models';

async function getDashboard(id: string): Promise<IDashboard> {
    const { data } = await axios.get(`/reporting/dashboard/${id}`);
    return data;
}

export const useDashboard = (id: string) => {
    return useQuery<IDashboard, AxiosError>({ queryKey: [queryKeys.DASHBOARDS, id], queryFn: () => getDashboard(id) });
};

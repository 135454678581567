import { Filter } from '@cubejs-client/core';
import { addDays } from 'date-fns';
import { mapDateToApiDate } from '../../support/mappers';

function getDateFilter(field: string, dateString: string, operator: 'beforeDate' | 'afterDate'): Filter {
    return {
        member: field,
        operator: operator,
        values: [dateString],
    };
}

export function dateFilterGenerator(field: string, startDate?: Date, endDate?: Date): Filter[] {
    const startDateString = mapDateToApiDate(startDate);
    const startDateFilter = startDateString && getDateFilter(field, startDateString, 'afterDate');

    const endDateString = endDate && mapDateToApiDate(addDays(endDate, 1));
    const endDateFilter = endDateString && getDateFilter(field, endDateString, 'beforeDate');

    const filters: Filter[] = [];
    if (startDateFilter) filters.push(startDateFilter);
    if (endDateFilter) filters.push(endDateFilter);
    return filters;
}

import { useEffect } from 'react';
import { Navigate, Route, Routes as RRRoutes, useNavigate } from 'react-router-dom';
import { adminRoutes } from '../../../admin';
import { registrationRoutes } from '../../../registration';
import { reportsRoutes } from '../../../reports';
import { IRouteConfig, IRouteConfigWithRedirect, Loading, ProtectedRoute, useHasPermission } from '../../../shared';
import { supportRoutes } from '../../../support';
import { PageNotFound } from '../../pages';
import { getFirstNavigationTab } from '../../utils';

export const ROUTES: IRouteConfig[] = [...adminRoutes, ...supportRoutes, ...registrationRoutes, ...reportsRoutes];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export function Routes() {
    const navigate = useNavigate();
    const { hasPermission, isLoading } = useHasPermission();

    useEffect(() => {
        if (!isLoading && location.pathname === '/') {
            navigate(getFirstNavigationTab(hasPermission));
        }
    }, [location, isLoading]);

    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ProtectedRoute requiredPermission={config.requiredPermissions}>
                                <config.component />
                            </ProtectedRoute>
                        }
                    />
                ),
            )}
            <Route path="/" element={<Loading />} />
            <Route path="*" element={<PageNotFound />} />
        </RRRoutes>
    );
}

import { useCubeQuery } from '@cubejs-client/react';
import { useIsFetching } from '@tanstack/react-query';
import { queryKeys } from '../../shared';
import { cubejsApi } from '../clients';
import { IFilter } from '../models';
import { dateFilterGenerator, filterGenerator } from '../utils';

export const useRegistrationPerSupportCaseReport = (
    filter: IFilter,
    dimensions = ['Registration.supporttypeid', 'Registration.organisationid'],
) => {
    const organisationsFetching = useIsFetching({ queryKey: [queryKeys.ORGANISATIONS] });
    const supportTypesFetching = useIsFetching({ queryKey: [queryKeys.SUPPORT_TYPES] });

    return useCubeQuery(
        {
            dimensions,
            timeDimensions: [
                {
                    dimension: 'Registration.dateofregistration',
                    granularity: filter.grouping || undefined,
                },
            ],
            order: {
                'Registration.dateofregistration': 'desc',
            },
            measures: [
                'Registration.count',
                'SupportCaseStatus.anonymousCount',
                'Registration.unique',
                'SupporteeStatus.count',
                'SupporteeStatus.unique',
            ],
            filters: [
                ...filterGenerator(filter.organisations || [], 'Registration.organisationid'),
                ...filterGenerator(filter.supportTypes || [], 'Registration.supporttypeid'),
                ...dateFilterGenerator('Registration.dateofregistration', filter.startDate, filter.endDate),
            ],
            limit: 5000,
        },
        { cubejsApi, skip: organisationsFetching != 0 || supportTypesFetching != 0 || !filter.enabled },
    );
};

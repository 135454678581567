export enum ChartColumnType {
    STRING = 'STRING',
    DATE = 'DATE',
}

export interface IChartColumn {
    name: string;
    label: string;
    type: ChartColumnType;
}

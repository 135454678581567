import { formatDateString, formatDateStringToMonth, formatDateStringToYear } from '../../shared';
import { TimeGranularity } from '../enums';

export const mapReportDate = (date: string, granularity: TimeGranularity) => {
    const granularityFunctions = {
        [TimeGranularity.DAY]: formatDateString,
        [TimeGranularity.MONTH]: formatDateStringToMonth,
        [TimeGranularity.YEAR]: formatDateStringToYear,
    };

    return granularityFunctions[granularity](date);
};

import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledDatePicker, ControlledSelect, FormGrid, Options, OptionsToggle } from '../../../shared';
import {
    DashboardFilterType,
    IAppliedDashboardFilterDto,
    IAppliedDateFilterDto,
    IAppliedSelectFilterDto,
    IDashboardFilter,
} from '../../models';

interface Props {
    availableFilters: IDashboardFilter[];
    onChange: (filters: IAppliedDashboardFilterDto[]) => void;
    filters: IAppliedDashboardFilterDto[];
}

type DashboardFilterForm = Record<string, string[] | { startDate?: string; endDate?: string }>;

const DOT_CHARACTER = '__DOT__';

export const DashboardFilter: FC<Props> = ({ availableFilters, filters, onChange }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        ...mapAvailableFilterToFilterOptions(availableFilters),
    });

    const form = useForm();

    const hasFilterFormChanged = useHasFilterFormChanged(form, filters);

    const onFilterChange = useCallback(
        (formValues: DashboardFilterForm) => {
            onChange(mapFormValuesToAppliedFilters(formValues));
        },
        [onChange],
    );

    useEffect(() => {
        filters.forEach((filter) => {
            if (filter.type === DashboardFilterType.DATE) {
                form.setValue('startDate', filter.startDate);
                form.setValue('endDate', filter.endDate);
            }
            if (filter.type === DashboardFilterType.SELECT) {
                const fieldName = getFieldNameFromTarget(filter.target);
                form.setValue(fieldName, filter.values);
            }
        });
    }, [filters, form.setValue]);

    useEffect(() => {
        setOptions({
            filter: { type: 'title', label: t('filter') },
            ...mapAvailableFilterToFilterOptions(availableFilters),
        });
    }, [availableFilters]);

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <OptionsToggle options={options} onChange={setOptions} />
            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                {availableFilters.map((filter) => {
                    if (!options[filter.name]?.active) return <Fragment key={filter.name} />;

                    const fieldName = getFieldNameFromTarget(filter.target);
                    return (
                        <Fragment key={filter.name}>
                            {filter.type === DashboardFilterType.DATE && (
                                <Stack direction="row" spacing={1}>
                                    <ControlledDatePicker
                                        name={`${fieldName}.startDate`}
                                        label={t('startDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                    <ControlledDatePicker
                                        name={`${fieldName}.endDate`}
                                        label={t('endDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                </Stack>
                            )}
                            {filter.type === DashboardFilterType.SELECT && (
                                <ControlledSelect
                                    name={fieldName}
                                    label={filter.name}
                                    form={form}
                                    options={filter.values || []}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </FormGrid>
            {hasFilterFormChanged && (
                <IconButton onClick={form.handleSubmit(onFilterChange)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};

function useHasFilterFormChanged(
    form: UseFormReturn<DashboardFilterForm>,
    appliedFilters: IAppliedDashboardFilterDto[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const newlyAppliedFilters = mapFormValuesToAppliedFilters(formValues);
        return JSON.stringify(newlyAppliedFilters) !== JSON.stringify(appliedFilters);
    }, [appliedFilters, formValues]);
}

function mapFormValuesToAppliedFilters(filterForm: DashboardFilterForm): IAppliedDashboardFilterDto[] {
    return Object.entries(filterForm)
        .map(([fieldName, filterValue]) => mapFilter(getTargetFromFieldName(fieldName), filterValue))
        .filter((appliedFilter) => !!appliedFilter) as IAppliedDashboardFilterDto[];
}

function mapFilter(
    target: string,
    value: string[] | { startDate?: string; endDate?: string },
): IAppliedDateFilterDto | IAppliedSelectFilterDto | undefined {
    if (isArrayValue(value)) return { type: DashboardFilterType.SELECT, target, values: value };
    if (isDateValue(value))
        return { type: DashboardFilterType.DATE, target, startDate: value.startDate, endDate: value.endDate };
}

function isArrayValue(value: string[] | { startDate?: string; endDate?: string }): value is string[] {
    return Array.isArray(value) && value.length > 0;
}

function isDateValue(
    value: string[] | { startDate?: string; endDate?: string },
): value is { startDate: string; endDate: string } {
    const castedValue = value as { startDate?: string; endDate?: string };
    return Boolean(castedValue.startDate && castedValue.endDate);
}

function mapAvailableFilterToFilterOptions(availableFilters: IDashboardFilter[]) {
    return availableFilters.reduce((filters, { name }) => ({ ...filters, [name]: { active: false, label: name } }), {});
}

function getFieldNameFromTarget(target: string): string {
    return target.replace('.', DOT_CHARACTER);
}

function getTargetFromFieldName(fieldName: string): string {
    return fieldName.replace(DOT_CHARACTER, '.');
}

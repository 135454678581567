import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSupportTypes } from '../../hooks';
import { ISupportType } from '../../models';
import { SelectComponent } from '../select/select.component';

interface Props {
    name: string;
    label: string;
    form: UseFormReturn;
}

export const ControlledSelectSupportTypes: FC<Props> = ({ name, label, form }) => {
    const { t } = useTranslation();
    const supportTypeOptions = useSupportTypes();
    const currentSupportTypes = form.watch(name);

    const getSupportTypeNameById = (id: string): string =>
        supportTypeOptions?.find((item) => item.id == id)?.name || t('unknown');

    const formatSelectedSupportTypes = (ids: string[]) =>
        ids?.length == supportTypeOptions?.length ? t('all') : ids.map(getSupportTypeNameById).join(', ');

    return (
        <SelectComponent
            name={name}
            label={label}
            control={form.control}
            multiple
            items={supportTypeOptions.map(({ id }) => id as string)}
            renderValue={(ids) => formatSelectedSupportTypes(ids as string[])}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox checked={currentSupportTypes?.length === (supportTypeOptions?.length || 0)} />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {supportTypeOptions.map((supportType: ISupportType) => (
                <MenuItem value={supportType.id} key={supportType.id}>
                    <Checkbox checked={currentSupportTypes?.includes(supportType.id as string)} />
                    <ListItemText primary={supportType.name} />
                </MenuItem>
            ))}
        </SelectComponent>
    );
};

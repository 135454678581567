import { Box, Button, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { startOfMonth } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoRowsOverlay, Page, useOrganisationRecords } from '../../../shared';
import { ReportFilter } from '../../components';
import { useNewSupportCasesReport } from '../../hooks';
import { IFilter } from '../../models';
import { mapReportDate, useCsvExport } from '../../utils';

export const ReportNewSupportCases: FC = () => {
    const { t } = useTranslation();

    const [filter, setFilter] = useState<IFilter>({
        startDate: startOfMonth(new Date()),
        endDate: undefined,
    });

    const organisations = useOrganisationRecords();

    const { resultSet, isLoading } = useNewSupportCasesReport(filter);

    const dataSource = useMemo(() => {
        const data = resultSet?.tablePivot().map((value: any) => ({
            ...value,
            [`SupportCase.createddate.${filter.grouping}`]: filter.grouping
                ? mapReportDate(value[`SupportCase.createddate.${filter.grouping}`] as string, filter.grouping)
                : '',
            'SupportCaseOrganisationsOrganisation.organisationid':
                organisations[value['SupportCaseOrganisationsOrganisation.organisationid']]?.name ||
                value['SupportCaseOrganisationsOrganisation.organisationid'],
        }));
        return data
            ? [
                  ...data,
                  {
                      key: 'total',
                      [`SupportCase.createddate.${filter.grouping}`]: t('all'),
                      'SupportCaseOrganisationsOrganisation.organisationid': t('all'),
                      ['SupportCase.count']: data.reduce((val1, val2) => val1 + Number(val2['SupportCase.count']), 0),
                  },
              ]
            : [];
    }, [resultSet]);

    const columns = resultSet?.tableColumns().map((col: any) => {
        return {
            field: col.key,
            headerName: t(`report.${col.key.toLowerCase()}`),
            flex: 1,
            sortable: false,
            filterable: false,
        } as GridColDef;
    });

    const csvExport = useCsvExport(columns || [], dataSource);

    return (
        <Page
            title={t('report.newSupportCasesTitle')}
            filter={<ReportFilter filter={filter} onChange={setFilter} showSupportTypes={false} />}
            actions={
                <Button
                    variant="contained"
                    href={csvExport}
                    component={Link}
                    download={t(`report.download.newSupportCases`)}
                >
                    {t('export')}
                </Button>
            }
            loading={isLoading || !resultSet}
        >
            <Box sx={{ '& .row-total': { fontWeight: 'bold', borderTop: '1px solid rgba(0, 0, 0, 0.6)' } }}>
                <DataGrid
                    autoHeight
                    columns={columns || []}
                    rows={dataSource || []}
                    getRowId={(row) =>
                        (filter.grouping ? row[`SupportCase.createddate.${filter.grouping}`] : '') +
                        row['SupportCaseOrganisationsOrganisation.organisationid']
                    }
                    hideFooter
                    getRowClassName={(params) => `row-${params.row.key}`}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                />
            </Box>
        </Page>
    );
};

import { useCubeQuery } from '@cubejs-client/react';
import { useIsFetching } from '@tanstack/react-query';
import { queryKeys } from '../../shared';
import { cubejsApi } from '../clients';
import { IFilter } from '../models';
import { dateFilterGenerator, filterGenerator } from '../utils';

export const useNewSupportCasesReport = (filter: IFilter) => {
    const organisationsFetching = useIsFetching({ queryKey: [queryKeys.ORGANISATIONS] });

    return useCubeQuery(
        {
            dimensions: ['SupportCaseOrganisationsOrganisation.organisationid'],
            timeDimensions: [
                {
                    dimension: 'SupportCase.createddate',
                    granularity: filter.grouping || undefined,
                },
            ],
            measures: ['SupportCase.count'],
            order: {
                'SupportCase.createddate': 'desc',
            },
            filters: [
                ...filterGenerator(filter.organisations || [], 'SupportCaseOrganisationsOrganisation.organisationid'),
                ...dateFilterGenerator('SupportCase.createddate', filter.startDate, filter.endDate),
            ],
        },
        { cubejsApi, skip: organisationsFetching !== 0 || !filter.enabled },
    );
};

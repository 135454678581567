import { TextField } from '@mui/material';
import React, { ComponentProps, FC, forwardRef } from 'react';
import { InputTextField } from '../input-text-field/input-text-field.component';
import { NumericFormat, InputAttributes, NumberFormatValues } from 'react-number-format';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    decimalScale?: number;
};

interface NumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    decimalScale?: number;
}

export const NumberInput = forwardRef<typeof NumericFormat<InputAttributes>, NumberFormatProps>(
    ({ onChange, name, decimalScale = 2, ...other }, ref) => {
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values: NumberFormatValues) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                }}
                allowLeadingZeros
                allowedDecimalSeparators={['.', ',']}
                thousandSeparator=" "
                decimalSeparator=","
                valueIsNumericString
                allowNegative={false}
                decimalScale={decimalScale}
            />
        );
    },
);

export const ControlledNumberInput: FC<Props> = ({ decimalScale, InputProps, ...textFieldProps }) => {
    return (
        <InputTextField
            InputProps={{
                inputComponent: NumberInput as any,
                ...InputProps,
            }}
            inputProps={{ decimalScale }}
            {...textFieldProps}
        />
    );
};

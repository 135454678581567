import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { useDashboards } from '../../hooks';
import { reportSubRoutes } from '../../reports.routes';

export const ReportPage: FC = () => {
    const { t } = useTranslation();
    const { data: dashboards = [] } = useDashboards();

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        { text: t('registration'), key: 'registration' },
                        {
                            path: '/reports/registrations',
                            text: t('report.registrations'),
                        },
                        {
                            path: '/reports/support-case-status',
                            text: t('report.registrationSupportCaseStatusTitle'),
                        },
                        {
                            path: '/reports/supportee-status',
                            text: t('report.registrationSupporteeStatusTitle'),
                        },
                        { text: t('cases'), key: 'support-cases' },
                        {
                            path: '/reports/new-support-cases',
                            text: t('report.newSupportCasesTitle'),
                        },
                        ...dashboards.map(({ id, name }) => ({
                            path: `/reports/dashboard/${id}`,
                            text: name,
                        })),
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {reportSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <subRoute.component />
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};

import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '../select/select.component';

interface Props {
    name: string;
    label: string;
    form: UseFormReturn;
    options: { name: string; value: string }[];
}

export const ControlledSelect: FC<Props> = ({ name, label, form, options }) => {
    const { t } = useTranslation();
    const currentValues = form.watch(name);

    const getOptionNameByValue = (value: string): string =>
        options?.find((option) => option.value === value)?.name || t('unknown');

    const formatSelectedValues = (values: string[]) =>
        options?.length === values?.length ? t('all') : values.map(getOptionNameByValue).join(', ');

    return (
        <SelectComponent
            name={name}
            label={label}
            control={form.control}
            multiple
            items={options.map(({ value }) => value)}
            renderValue={(values) => formatSelectedValues(values as string[])}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox checked={currentValues?.length === (options?.length || 0)} />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                    <Checkbox checked={currentValues?.includes(option.value) || false} />
                    <ListItemText primary={option.name} />
                </MenuItem>
            ))}
        </SelectComponent>
    );
};

import { Box, Button, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { startOfMonth } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoRowsOverlay, Page, useOrganisationRecords, useSupportTypesRecord } from '../../../shared';
import { ReportFilter } from '../../components';
import { useRegistrationPerSupportCaseReport } from '../../hooks';
import { IFilter } from '../../models';
import { mapReportDate, useCsvExport } from '../../utils';

export const ReportRegistrationSupportCaseStatus: FC = () => {
    const { t } = useTranslation();

    const [filter, setFilter] = useState<IFilter>({
        startDate: startOfMonth(new Date()),
        endDate: undefined,
    });

    const organisations = useOrganisationRecords();
    const supportTypes = useSupportTypesRecord();

    const { resultSet, isLoading } = useRegistrationPerSupportCaseReport(filter);
    const { resultSet: allOrganisations, isLoading: reportAllOrganisationsIsLoading } =
        useRegistrationPerSupportCaseReport({ ...filter, grouping: undefined }, []);

    const dataSource = useMemo(() => {
        const data = resultSet?.tablePivot().map((value: any) => ({
            ...value,
            [`Registration.dateofregistration.${filter.grouping}`]: filter.grouping
                ? mapReportDate(value[`Registration.dateofregistration.${filter.grouping}`] as string, filter.grouping)
                : '',
            'Registration.organisationid':
                organisations[value['Registration.organisationid']]?.name || value['Registration.organisationid'],
            'Registration.supporttypeid':
                supportTypes[value['Registration.supporttypeid']]?.name || value['Registration.supporttypeid'],
        }));

        const allOrganisationsMappedData =
            allOrganisations?.tablePivot().map((value: any) => ({
                ...value,
                [`Registration.dateofregistration.${filter.grouping}`]: t('all'),
                'Registration.organisationid': t('all'),
                'Registration.supporttypeid': t('all'),
            })) || [];
        return data ? [...data, ...allOrganisationsMappedData.map((data) => ({ ...data, key: 'total' }))] : [];
    }, [resultSet, allOrganisations]);

    const columns = resultSet?.tableColumns().map((col: any) => {
        return {
            field: col.key,
            headerName: t(`report.${col.key.toLowerCase()}`),
            flex: 1,
            sortable: false,
            filterable: false,
        } as GridColDef;
    });
    const csvExport = useCsvExport(columns || [], dataSource);

    return (
        <Page
            title={t('report.registrationSupportCaseStatusTitle')}
            filter={<ReportFilter filter={filter} onChange={setFilter} />}
            actions={
                <Button
                    variant="contained"
                    href={csvExport}
                    component={Link}
                    download={t(`report.download.registrationSupportCaseStatus`)}
                >
                    {t('export')}
                </Button>
            }
            loading={!resultSet || !allOrganisations || reportAllOrganisationsIsLoading || isLoading}
        >
            <Box sx={{ '& .row-total': { fontWeight: 'bold', borderTop: '1px solid rgba(0, 0, 0, 0.6)' } }}>
                <DataGrid
                    autoHeight
                    columns={columns || []}
                    rows={dataSource || []}
                    getRowId={(row) =>
                        (filter.grouping ? row[`Registration.dateofregistration.${filter.grouping}`] : '') +
                        row['Registration.organisationid'] +
                        row['Registration.supporttypeid']
                    }
                    hideFooter
                    getRowClassName={(params) => `row-${params.row.key}`}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                />
            </Box>
        </Page>
    );
};

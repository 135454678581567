import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    DashboardPage,
    ReportNewSupportCases,
    ReportPage,
    ReportRegistrations,
    ReportRegistrationSupportCaseStatus,
    ReportRegistrationSupporteeStatus,
} from './pages';

export const reportsRoutes: IRouteConfig[] = [
    { path: '/reports', redirect: '/reports/registrations' },
    { path: '/reports/*', component: ReportPage },
];

export const reportSubRoutes: IRouteConfigWithComponent[] = [
    { path: '/supportee-status', component: ReportRegistrationSupporteeStatus },
    { path: '/support-case-status', component: ReportRegistrationSupportCaseStatus },
    { path: '/new-support-cases', component: ReportNewSupportCases },
    { path: '/registrations', component: ReportRegistrations, requiredPermissions: PermissionKeys.REGISTRATIONS_READ },
    { path: '/dashboard/:id', component: DashboardPage },
];
